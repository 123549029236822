import React, { useState, useRef, useEffect } from 'react'
import {
  InputGroup,
  TextInput,
  BoxProps,
  InputRightElement,
  Box,
  Flex,
  Icon,
  useProductSearch,
  Spinner,
  IconButton,
  useTheme,
  useRouteChange,
  useBodyScrollLock,
  useAsyncDebounce,
} from '@thirstycamel/ui'
import { Portal } from 'react-portal'
import useDimensions from 'react-use-dimensions'
import useMedia from 'use-media'
import type { CoreProductEntity } from '@ts/core/src/modules/coreproduct/coreproduct.entity'
import { useDebounce } from 'use-debounce/lib'

import { useStore, useActions } from '../../store/hooks'
import SearchPopout from '../SearchPopout'
import { eventGA4, eventUA } from '../../utils/gtag'

export interface MainHeadNavSearchProps extends BoxProps {
  isFullWith?: boolean
}

export const MainHeadNavSearch = ({ isFullWith = true, ...restProps }: MainHeadNavSearchProps) => {
  const theme = useTheme()

  const { isPopoutVisible } = useStore(store => store.search)
  const { setPopoutVisible } = useActions(store => store.search)

  const [searchValue, setSearchValue] = useState('')
  const [debouncedSearchValue] = useDebounce(searchValue, 300) //  Used for analytics

  const inputRef = useRef(null)

  const isMobile = useMedia({ maxWidth: '30em' })
  const isTablet = useMedia({ maxWidth: '48em' })

  const selectedStore = useStore(s => s.store.selected)
  const region = useStore(s => s.location?.region)

  const products = useProductSearch<CoreProductEntity>({
    store: selectedStore,
    itemsPerPage: isTablet ? 10 : 3,
    page: 1,
    region: region,
  })

  const [ref, dimensions] = useDimensions()

  const numberOfResults = products.resolvedData?.results?.meta?.totalItems || 0

  useBodyScrollLock(isPopoutVisible, [isMobile, isTablet])

  useEffect(() => {
    /* Prevent the search event being fired if no search term is given */
    if (!searchValue?.length) return
    /* Send to Google Analytics */
    eventUA('search', { event_label: 'Search', search_term: searchValue })
    eventGA4('search', { search_term: searchValue })
  }, [debouncedSearchValue])

  useEffect(() => {
    if (products.resolvedData?.results?.items?.length > 0) {
      /* Send to Google Analytics */
      eventUA('view_search_results', {
        event_label: 'View search results',
        search_term: searchValue,
        items: products.resolvedData?.results?.items?.map((item, index) => ({
          item_id: item.id,
          item_name: item.productGroup?.name,
          item_brand: item.productGroup?.brand?.name,
          item_category: item.productGroup?.categories?.map(cat => cat.name).join('/'),
          item_variant: item.factorDescription,
          price: (item.specialPricing?.price ?? item.pricing?.price ?? 0) / 100,
          currency: 'AUD',
          item_list_name: 'Navbar Search',
          index,
        })),
      })

      eventGA4('view_search_results', {
        event_label: 'View search results',
        search_term: searchValue,
        items: products.resolvedData?.results?.items?.map((item, index) => ({
          item_id: item.id,
          item_name: item.productGroup?.name,
          item_brand: item.productGroup?.brand?.name,
          item_category: item.productGroup?.categories?.map(cat => cat.name).join('/'),
          item_variant: item.factorDescription,
          price: (item.specialPricing?.price ?? item.pricing?.price ?? 0) / 100,
          currency: 'AUD',
          item_list_name: 'Navbar Search',
          index,
        })),
      })

      /* Send to Facebook Pixel */
      if (typeof fbq !== 'undefined') {
        fbq?.('track', 'Search', {
          search_string: searchValue,
          content_type: 'product',
          content_ids: products.resolvedData?.results?.items?.map(item => item.id),
        })
      }
    }
  }, [products])

  const handleChangeValue = value => {
    setSearchValue(value)

    products.setSearchValue(value)
    setSearchValueDisplay(value)
    setPopoutVisible(true)
  }

  const [searchValueDisplay, _setSearchValueDisplay] = useState()
  const setSearchValueDisplay = useAsyncDebounce<any>(_setSearchValueDisplay, 500)

  const handleFocus = () => {
    const hasProducts = products.resolvedData?.results?.items?.length > 0

    if (hasProducts) {
      setPopoutVisible(true)
    }
  }

  const handleClickOpen = () => {
    if (searchValue) {
      window.location.href = `/search?search=${encodeURIComponent(searchValue)}`
    } else {
      setPopoutVisible(true)
      setTimeout(() => {
        inputRef.current?.focus?.()
      }, 50)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (searchValue) {
      window.location.href = `/search?search=${encodeURIComponent(searchValue)}`
    }
  }

  const handleClickClose = () => {
    setPopoutVisible(false)
  }

  const handleRouteChange = () => {
    if (isPopoutVisible) {
      setPopoutVisible(false)
    }
  }

  useRouteChange(handleRouteChange)

  return (
    <Flex w={isFullWith ? '100%' : null} h="100%" ref={ref}>
      <Portal>
        <Box {...restProps}>
          {isPopoutVisible && (
            <Box
              position="fixed"
              top={0}
              right={0}
              bottom={0}
              left={0}
              bg="blackAlpha.300"
              zIndex={theme.zIndices.popover - 1}
              onClick={() => setPopoutVisible(false)}
            />
          )}

          <Flex
            align="center"
            justify="flex-end"
            position="fixed"
            left={dimensions.x}
            top={0}
            height={`${dimensions.height}px`}
            width={dimensions.width}
            zIndex={isPopoutVisible ? theme.zIndices.popover : theme.zIndices.docked} // Open: popout, closed: navbar
            display={['flex', 'none']}
          >
            <Flex
              bg="green.500"
              h="100%"
              align="center"
              px={isPopoutVisible ? 5 : null}
              mx={isPopoutVisible ? -5 : null}
            >
              <IconButton
                icon={isPopoutVisible ? 'close' : 'search'}
                isRound
                bg="whiteAlpha.600"
                color="pink.500"
                size="md"
                fontSize={isPopoutVisible ? 'xs' : 'md'}
                hasDepth={false}
                onClick={isPopoutVisible ? handleClickClose : handleClickOpen}
              />
            </Flex>
          </Flex>

          <Flex
            w="100%"
            h="86px"
            align="center"
            bg={isPopoutVisible ? 'green.500' : null}
            px={8}
            position="fixed"
            top={['64px', 0]}
            left={[0, dimensions.x - 16, , , dimensions.x - 32]}
            width={['100%', dimensions.width + 32, , , dimensions.width + 48]}
            maxWidth="45rem"
            zIndex={isPopoutVisible ? theme.zIndices.popover : theme.zIndices.docked} // Open: popout, closed: navbar
            display={[isPopoutVisible ? 'flex' : 'none', 'flex']}
          >
            <InputGroup w="100%" onSubmit={handleSubmit}>
              <TextInput
                type="search"
                placeholder={isTablet ? 'Search...' : 'Get your search on...'}
                borderRadius="md"
                bg="whiteAlpha.600"
                border={0}
                pr="3rem"
                name="searchValue"
                value={searchValue}
                isRequired
                _placeholder={{ color: 'blackAlpha.600' }}
                _hover={{ bg: 'whiteAlpha.700' }}
                _focus={{ bg: 'whiteAlpha.800' }}
                onChangeValue={handleChangeValue}
                onFocus={handleFocus}
                ref={inputRef}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleSubmit(e)
                  }
                }}
              />

              <InputRightElement>
                <Flex
                  bg="whiteAlpha.700"
                  h="100%"
                  w="3rem"
                  justify="center"
                  align="center"
                  borderRadius="md"
                  userSelect="none"
                  cursor="pointer"
                  onClick={handleSubmit}
                >
                  {products.isFetching ? (
                    <Spinner size="sm" color="pink.500" />
                  ) : (
                    <Icon name="search" color="pink.500" />
                  )}
                </Flex>
              </InputRightElement>
            </InputGroup>
          </Flex>

          {isPopoutVisible && (
            <SearchPopout
              dimensions={dimensions}
              products={products.resolvedData?.results?.items}
              searchValue={searchValueDisplay}
              setSearchValue={setSearchValue}
              isFetching={products.isFetching}
              error={products.error}
              numberOfResults={numberOfResults}
            />
          )}
        </Box>
      </Portal>
    </Flex>
  )
}

export default MainHeadNavSearch
